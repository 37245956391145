import thunk from "redux-thunk";
import { unstable_batchedUpdates } from 'react-dom';
import { batchedSubscribe } from 'redux-batched-subscribe';
import rootReducer from "./rootReducer";
import {pendingAPIReducerMiddleware} from "../main/platform/dashboards/pendings/ducks";
import {notificationAPIReducerMiddleware} from "../main/platform/dashboards/notifications/ducks";
import {analyticsAPIReducerMiddleware} from "../main/platform/dashboards/overview/ducks";
import {eventsAPIReducerMiddleware, causesAPIReducerMiddleware} from
        "../main/platform/dashboards/overview/eventView/ducks";
import {configureStore} from "@reduxjs/toolkit";
import {versionAPIReducerMiddleware} from "../main/ducks";
import {maintenanceEventAPIReducerMiddleware} from "../main/platform/dashboards/pendings/ducks/maintenanceEvent";
import {alarmsAPIReducerMiddleware} from "../main/platform/dashboards/alarms/ducks";
import {reportsAPIReducerMiddleware} from "../main/platform/dashboards/reports/ducks";
import {hardwareAPIReducerMiddleware} from "../main/platform/dashboards/reports/ducks/hardwareDuck";
import {
    diagnosticsAPIReducerMiddleware, findingsAPIReducerMiddleware,
    evidenceAPIReducerMiddleware, featuresAPIReducerMiddleware,
    problemsAPIReducerMiddleware
} from "../main/platform/dashboards/analysis/ducks";
import {usersAPIReducerMiddleware} from "../main/platform/dashboards/User/ducks";
import {configurationAPIReducerMiddleware} from "../main/platform/dashboards/configuration/ducks";
import {messagesAPIReducerMiddleware} from "../main/platform/dashboards/inbound_messages/ducks";
import { bearingsAPIReducerMiddleware } from "../main/platform/dashboards/configuration/ducks/bearingsData";
import { dashboardsAPIReducerMiddleware } from "../main/platform/dashboards/components/highcharts/dashboard/ducks";
import { internationalizationAPIReducerMiddleware } from "../main/platform/ducks";
import { mediaAPIReducerMiddleware } from "../main/platform/dashboards/overview/ducks/mediaDuck";
import { orientationAPIReducerMiddleware } from "../main/platform/dashboards/analysis/ducks/orientationDuck";
import {bestPracticeAPIReducerMiddleware} from "../main/platform/dashboards/analysis/ducks/bestPracticeDuck";
import { managementNodesAPIReducerMiddleware } from "../main/platform/dashboards/configuration/ducks/managementNodes";
import { installationAPIReducerMiddleware } from "../main/platform/dashboards/configuration/ducks/wiverInstallation";
import { exportAPIReducerMiddleware } from "../main/platform/dashboards/diagnostics/ducks/export";
import { measurementsAPIReducerMiddleware } from "../main/platform/dashboards/analysis/ducks/measurementsDuck";

export default function configureAppStore() {
    const store = configureStore({
        reducer:rootReducer,
        middleware: [thunk, pendingAPIReducerMiddleware, notificationAPIReducerMiddleware,
            eventsAPIReducerMiddleware, causesAPIReducerMiddleware, analyticsAPIReducerMiddleware,
            versionAPIReducerMiddleware, maintenanceEventAPIReducerMiddleware, alarmsAPIReducerMiddleware,
            reportsAPIReducerMiddleware, hardwareAPIReducerMiddleware, problemsAPIReducerMiddleware,
            findingsAPIReducerMiddleware, evidenceAPIReducerMiddleware, diagnosticsAPIReducerMiddleware,
            usersAPIReducerMiddleware, featuresAPIReducerMiddleware, configurationAPIReducerMiddleware,
            messagesAPIReducerMiddleware, bearingsAPIReducerMiddleware, dashboardsAPIReducerMiddleware,
            internationalizationAPIReducerMiddleware, mediaAPIReducerMiddleware, orientationAPIReducerMiddleware,
            bestPracticeAPIReducerMiddleware, managementNodesAPIReducerMiddleware,
            installationAPIReducerMiddleware, exportAPIReducerMiddleware, measurementsAPIReducerMiddleware
        ],
        devTools: true,
        enhancers: [batchedSubscribe(unstable_batchedUpdates)],
    });
    return store;
}


