import { createApi } from '@reduxjs/toolkit/query/react'
import { backend_server_address } from "../../../../../backendServer";
import { axiosBaseQuery } from '../../../../../store/utility';

export const installationAPI = createApi({
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address + '/api/model/installation/'}),
    endpoints: (builder) => ({
        uploadInstallation: builder.mutation({
            query: ({data}) => {
                return {
                    url: '',
                    method: 'POST',
                    withCredentials: true,
                    data: data
                }
            }
        })
    })
});

export const {
    endpoints,
    useUploadInstallationMutation
} = installationAPI;
export const installationAPIReducer = installationAPI.reducer;
export const installationAPIReducerPath = installationAPI.reducerPath;
export const installationAPIReducerMiddleware = installationAPI.middleware;