import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../../../../store/utility';
import { backend_server_address } from '../../../../../backendServer';

export const measurementsAPI = createApi({
    reducerPath: 'measurementsAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/model/measurements/'}),
    tagTypes: ['Measurements', 'MeasInfo'],
    endpoints: (builder) => ({
        getMeasurements: builder.query({
            query: (params) => {
                return {
                    url: `get/`,
                    method: 'GET',
                    withCredentials: true,
                    params: params
                }
            },
            providesTags: (result) => ['Measurements'],
        }),
        getMeasInfo: builder.query({
            query: (params) => {
                return {
                    url: `info/`,
                    method: 'GET',
                    withCredentials: true,
                    params: params
                }
            },
            providesTags: (result) => ['MeasInfo'],
        }),
    }),
});

export const {
    endpoints,
    useGetMeasurementsQuery,
    useLazyGetMeasInfoQuery,
} = measurementsAPI;
export const measurementsAPIReducer = measurementsAPI.reducer;
export const measurementsAPIReducerPath = measurementsAPI.reducerPath;
export const measurementsAPIReducerMiddleware = measurementsAPI.middleware;